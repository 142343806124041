import { trpc } from '@api/bff/client';
import {
  TRACKING_LANDING_PAGE,
  TRACKING_LANDING_PAGE_CLICK,
} from '@shared/constants/Tracking.constants';
import { useTracking } from '@shared/hooks/useTracking';
import { useUserAuth } from '@shared/hooks/useUserAuth';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import hero from '../../assets/travel-1.png';
import { SlideItem } from './components/slider';
import {
  useBenefitsNumbers,
  useFutureFeatures,
  useSolutionFeatures,
} from './hooks';
import { BigNumberItem, BigNumbers } from './sections/BigNumbers';
import { ContactRequestedModal } from './sections/ContactRequestedModal';
import { FutureFeaturesSection } from './sections/FutureFeatures';
import { Hero } from './sections/Hero';
import { LandingPageLoading } from './sections/Loading';
import { Solutions } from './sections/Solutions';
import * as SC from './styled';

const Page = () => {
  const user = useUserAuth();
  const solutions: SlideItem[] = useSolutionFeatures();
  const nearFeatures = useFutureFeatures();
  const numbers: BigNumberItem[] = useBenefitsNumbers();
  const tracking = useTracking();
  const [showLoadingButton, setShowLoadingButton] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(true);

  const { data: hasPendingContracts, isLoading: isCheckingContract } =
    trpc.hasPendingContracts.useQuery(undefined, {
      retry: false,
    });

  const navigate = useNavigate();

  const openContractPage = useCallback(() => {
    navigate(`/expenseManagement/subscription?module=travel`);
  }, [navigate]);

  useEffect(() => {
    if (hasPendingContracts) {
      openContractPage();
    }
  }, [hasPendingContracts, openContractPage]);

  useEffect(() => {
    tracking.trackPage({
      name: TRACKING_LANDING_PAGE,
    });
    setShowLoading(false);
  }, [tracking]);

  const callToAction = async () => {
    tracking.trackEvent({
      name: TRACKING_LANDING_PAGE_CLICK,
    });

    setShowLoadingButton(true);

    openContractPage();

    setShowLoadingButton(false);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      {showLoading || isCheckingContract ? (
        <LandingPageLoading />
      ) : (
        <SC.PageContainer>
          <Hero
            img={hero}
            greeting="Conheça a solução de Viagens Corporativas da Flash!"
            title="As viagens corporativas nunca foram tão simples de organizar."
            subtitle="Reserve passagens e hotéis, garanta o cumprimento das políticas da empresa e aprove solicitações em tempo real. Economia e eficiência do início ao fim para você voar mais alto!"
            buttonText="Contrate agora!"
            onClickButton={callToAction}
            loadingButton={showLoadingButton}
          />
          <Solutions
            title="Soluções para a sua empresa decolar com a Flash"
            solutions={solutions}
          />
          <BigNumbers
            items={numbers}
            buttonText="Contrate agora!"
            onClickButton={callToAction}
            loadingButton={showLoadingButton}
          >
            Conte com uma ferramenta que otimiza os{' '}
            <SC.HighlightedText>números</SC.HighlightedText> da sua empresa
          </BigNumbers>
          <FutureFeaturesSection
            title="E ainda tem mais! Confira o que vem por aí:"
            buttonText="Contrate agora!"
            features={nearFeatures}
            onClickButton={callToAction}
            loadingButton={showLoadingButton}
          />
        </SC.PageContainer>
      )}

      <ContactRequestedModal
        open={showModal}
        onActionClick={closeModal}
        userName={user.name}
      />
    </div>
  );
};

export default Page;

export interface FutureFeatures {
  name: string;
  released: boolean;
}
export function useFutureFeatures(): FutureFeatures[] {
  return [
    {
      name: 'Aprovação de viagens pelo aplicativo Flash oficial',
      released: true,
    },
    { name: 'Formulário de pedido de veículo via plataforma', released: true },
    { name: 'Pagamento integrado com cartão Flash', released: true },
    { name: 'Hotel internacional', released: true },
    { name: 'Integração NDC', released: true },
    { name: 'Integração automática com o módulo de despesas', released: false },
    { name: 'Marcação de assentos', released: false },
    { name: 'Aluguel de veículo online', released: false },
    { name: 'Aluguel de rodoviário online', released: false },
  ];
}
